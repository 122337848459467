

.icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  border: 3px solid #f15e5e;
  color: #f15e5e;
  font-size: 46px;
}
