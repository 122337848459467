
.field-wrap, .field-wrap > div, .input-group {
    width: 100%;
}

ul.checkbox-group {
    margin-left: 0;
    padding-left: 0;

    li {
        list-style: none;
    }
}
