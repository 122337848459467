<template>
  <div class="overflow-hidden w-100 bg-warning error-overlay">
    <div class="container d-flex align-items-stretch min-vh-100 p-0">
      <div class="row w-100">
        <div class="d-flex col-md justify-content-center align-items-center order-1 order-md-1 position-relative p-5">
          <div class="error-bg-skew bg-white"></div>
          <div class="text-md-left text-center">
            <h1 class="display-2 font-weight-bolder mb-4">Whoops...</h1>
            <div class="text-xlarge font-weight-light mb-5">Something went wrong.<br>
              Please contact support if the error persists!
            </div>
            <button @click="back()" class="btn btn-warning">←&nbsp; Go Back</button>
          </div>
        </div>

        <div
            class="d-flex col-md-5 justify-content-center align-items-center order-2 order-md-2 text-center text-white p-5">
          <div>
            <div class="error-code font-weight-bolder mb-2">500</div>
            <div class="error-description font-weight-light">Error</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ErrorPage extends Vue {
  back() {
    if (window.history.length > 0) {
      this.$router.go(-1)
    } else {
      this.$router.push("/")
    }
  }
}
</script>

<style scoped>
.error-overlay {
  z-index: 999;
  position: absolute;
}

.error-code,
.error-description {
  line-height: 1;
}

.error-code {
  font-size: 100px;
}

.error-description {
  font-size: 28px;
}

.error-bg-skew {
  position: absolute;
  top: 0;
  right: -50vw;
  bottom: 0;
  z-index: -1;
  width: 200vw;
}

[dir=rtl] .error-bg-skew {
  right: auto;
  left: -50vw;
}

@media (min-width: 768px) {
  .error-bg-skew {
    right: 0;
    width: 100vw;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }

  [dir=rtl] .error-bg-skew {
    right: auto;
    left: 0;
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
}
</style>
