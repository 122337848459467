
#eqify-app {
  padding-left: 300px;
  padding-right: 0;
  transition: 0.3s ease;
}

#eqify-app.collapsed {
  padding-left: 50px;
}

#eqify-app.onmobile {
  padding-left: 50px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

