
.championship-card {
  cursor: pointer;
}

.content-top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}
