<template>
  <ul class="listStyle pt-1" @click="hide()">
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/general'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="circle-notch" size="sm"/>
        {{ $t("menu.myfederation.general") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/users'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="users" size="sm"/>
        {{ $t("menu.myfederation.users") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/usergroups'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="comments" size="sm"/>
        {{ $t("menu.myfederation.usergroups") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/sporttypevariants'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="horse" size="sm"/>
        {{ $t("menu.myfederation.sporttypevariants") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/sportcategories'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="list-ol" size="sm"/>
        {{ $t("menu.myfederation.sportcategories") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/careers'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="chart-line" size="sm"/>
        {{ $t("menu.myfederation.careers") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/protocols'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="gavel" size="sm"/>
        {{ $t("menu.myfederation.protocols") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/licenses'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="id-badge" size="sm"/>
        {{ $t("menu.myfederation.licenses") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/member-benefits'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="user-plus" size="sm"/>
        {{ $t("menu.myfederation.memberbenefit") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/achievements'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="trophy" size="sm"/>
        {{ $t("menu.myfederation.achievements") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/courses'"
              v-if="canForFederation(federation)">
        <font-awesome-icon icon="book" size="sm"/>
        {{ $t("menu.myfederation.courses") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/rulestemplate'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="tasks" size="sm"/>
        {{ $t("menu.myfederation.rules") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/price-profile'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="comments-dollar" size="sm"/>
        {{ $t("menu.myfederation.priceprofiles") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/practitioner-price-profile'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="user-tag" size="sm"/>
        {{ $t("menu.myfederation.practitionerpriceprofiles") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/practitioner-group'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="users-cog" size="sm"/>
        {{ $t("menu.myfederation.practitionergroup") }}
      </b-link>
    </li>
    <li>
      <b-link class="dropdown-item" :to="'/myfederation/'+ federation.ref+'/finance'"
              v-if="canManageForFederation(federation)">
        <font-awesome-icon icon="coins" size="sm"/>
        {{ $t("menu.myfederation.finance") }}
      </b-link>
    </li>
  </ul>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import {FederationUserInfo} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";

@Component({
  components: {EqifyAvatarAndText}
})
export default class PageNavbarFederationDropdown extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() federation: FederationUserInfo | undefined;

  canManageForFederation(federation: FederationUserInfo) {
    return this.user!.canManageFederationData(federation.ref!!)
  }

  canForFederation(federation: FederationUserInfo) {
    return this.user!.canManageAndReadFederationData(federation.ref!!)
  }

  hide() {
    this.$emit('hide')
  }
}
</script>

<style scoped>
.listStyle {
  padding: 0;
  list-style-type: none;
}
</style>
