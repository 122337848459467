@import "src/styles/variables";

$sidebar-header-height: 100px;
$sidebar-footer-height: 40px;

.fast_navigation_sidebar {
  width: 500px;
  height: calc(100vh - $footer-height) !important;

  .fast_navigation_sidebar_header {
    position: absolute;
    top: 0;
    height: $sidebar-header-height;
  }

  .fast_navigation_sidebar_content_divider {
    height: $sidebar-header-height;
  }

  .fast_navigation_sidebar_content {
    height: calc(100vh - $footer-height - $sidebar-header-height - $sidebar-footer-height) !important;
    overflow: auto;
    z-index: -1;
  }

  .fast_navigation_sidebar_footer {
    position: absolute;
    bottom: 0;
    height: $sidebar-footer-height !important;
  }
}

.fast_navigation_sidebar_folded {
  width: 50px;
  height: 100vh !important;
}